<template>
  <div class="course-library-add">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">{{ id ? '编辑基本信息' : '新增内训师' }}</span>
      </div>
      <el-form ref="form" :model="form" label-width="140px" :rules="rules">
        <el-row>
          <el-col :span="12">
            <el-form-item label="内训师所属：" prop="teacherType">
              <el-radio-group v-model="form.teacherType" @change="handleChooseradio">
                <el-radio :label="1">企业内部</el-radio>
                <el-radio :label="2">企业外聘</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="内训师姓名：" prop="userName">
              <el-input
                v-model="form.userName"
                placeholder="请输入内训师名称"
                clearable
                :disabled="Isable"
              ></el-input>
              <el-button v-if="form.teacherType == 1" @click="selectTrainer"
                >请从员工中选取</el-button
              >
            </el-form-item>
            <el-form-item label="手机号码：" prop="phone">
              <el-input
                v-model="form.phone"
                placeholder="请输入手机号码"
                clearable
                maxlength="11"
                :disabled="Isable"
              ></el-input>
            </el-form-item>
            <el-form-item label="性别：" prop="sex">
              <el-radio-group v-model="form.sex">
                <el-radio :label="1">男</el-radio>
                <el-radio :label="2">女</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="出生日期：">
              <el-date-picker
                v-model="form.birthday"
                type="date"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
            <el-form-item label="邮箱：" prop="email">
              <el-input v-model="form.email" placeholder="请输入邮箱" clearable></el-input>
            </el-form-item>
            <el-form-item label="常驻地区：" prop="Address">
              <el-cascader
                ref="myCascader"
                v-model="city"
                :options="total"
                placeholder="请选择省/市/区"
                @change="handleChangeAddress"
              >
              </el-cascader>
            </el-form-item>
            <el-form-item label="课酬金额：">
              <el-input
                v-model.trim="form.courseFee"
                placeholder="请输入课酬金额"
                clearable
                maxlength="7"
                @keyup.native="form.courseFee = inputChange(form.courseFee)"
              ></el-input
              >元/课时
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item ref="pic" label="上传图像：" prop="pic">
              <upload-image
                v-model="form.pic"
                :upload-img="$refs.pic"
                :width-size="widthSize"
                :height-size="heightSize"
              ></upload-image>
              <p class="tip">图片大小在106*132，支持扩展名：.png .jpg .jpeg</p>
            </el-form-item>
            <el-form-item label="最高学历：" prop="education">
              <el-select v-model="form.education" placeholder="请选择">
                <el-option label="本科" value="本科"></el-option>
                <el-option label="硕士" value="硕士"></el-option>
                <el-option label="博士" value="博士"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="最高学历学校名称：">
              <el-input v-model="form.school" placeholder="请输入学校名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="职称：" prop="itTitle">
              <el-select v-model="form.itTitle" placeholder="请选择">
                <el-option label="助教" :value="1"></el-option>
                <el-option label="中级" :value="2"></el-option>
                <el-option label="副高" :value="3"></el-option>
                <el-option label="正高" :value="4"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="内训师等级：" prop="itLevel">
              <el-select v-model="form.itLevel" placeholder="请选择">
                <el-option label="一级" :value="1"></el-option>
                <el-option label="二级" :value="2"></el-option>
                <el-option label="三级" :value="3"></el-option>
                <el-option label="四级" :value="4"></el-option>
                <el-option label="五级" :value="5"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="内训师性质：" prop="nature">
              <el-radio-group v-model="form.nature">
                <el-radio :label="1">社会内训师</el-radio>
                <el-radio :label="2">大学内训师/教授</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="内训师简介描述:" prop="itDescribe">
            <tinymce ref="editor" v-model="form.itDescribe"></tinymce>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
          </el-form-item>
        </el-row>
      </el-form>
    </el-card>
    <TrainerDialog ref="TrainerDialog" @handleTeachersChange="handleTeachersChange"></TrainerDialog>
  </div>
</template>
<script>
import to from 'await-to'
import { saveTrainer, findByIdTrainer, updataTrainer } from '@/api/teacher'
import UploadImage from '@/components/UploadImage'
import TrainerDialog from './components/TrainerDialog'
import total from '../../../assets/address/city'
import Tinymce from '@/components/Tinymce'
export default {
  name: 'TrainerAdd',
  components: { UploadImage, TrainerDialog, Tinymce },
  data() {
    // const rulesloginId = (rule, value, callback) => {
    //   if (!/^1[34578]\d{9}$/.test(value)) {
    //     callback('手机号信息有误')
    //   } else {
    //     callback()
    //   }
    // }
    return {
      widthSize: 106,
      heightSize: 132,
      rules: {
        teacherType: [{ required: true, message: '请选择内训师所属', trigger: 'change' }],
        userName: [{ required: true, message: '请输入内训师名称”，限制2-12字', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          // 这个只能验证手机号
          // { pattern:/^0{0,1}(13[0-9]|15[7-9]|153|156|18[7-9])[0-9]{8}$/, message: "请输入合法手机号", trigger: "blur" }
          {
            pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
            message: '请输入合法手机号/电话号',
            trigger: 'blur',
          },
        ],
        email: [
          { required: true, message: '请输入邮箱', trigger: 'blur' },
          {
            pattern: /^([0-9A-Za-z\-_.]+)@([0-9a-z]+\.[a-z]{2,3}(\.[a-z]{2})?)$/g,
            message: '请输入正确的邮箱',
            trigger: 'blur',
          },
        ],
        Address: [{ type: 'array', required: true, message: '地址不能为空', trigger: 'blur' }],
        pic: [{ required: true, message: '请上传讲师头像', trigger: 'change' }],
        itLevel: [{ required: true, message: '请选择内训师等级', trigger: 'change' }],
        itDescribe: [{ required: true, message: '简介不能为空', trigger: 'blur' }],
      },
      orgId: '',
      loading: false,
      form: {
        teacherType: 1,
        userName: '',
        phone: '',
        sex: 1,
        birthday: '',
        email: '',
        provinceName: '',
        provinceCode: '',
        cityName: '',
        cityCode: '',
        courseFee: null,
        pic: '',
        education: '',
        school: '',
        itTitle: null,
        itLevel: null,
        nature: 1,
        itDescribe: '',
        userId: 0,
        Address: [],
      },
      Isable: true,
      teachers: {},
      total,
      city: [],
      id: '',
    }
  },
  mounted() {
    const id = this.$route.query.id
    this.id = id
    if (id) this.getTrainerInfo()
  },
  methods: {
    //效验输入框
    inputChange(num) {
      let str = num.toString()
      var len1 = str.substr(0, 1)
      var len2 = str.substr(1, 1)
      //如果第一位是0，第二位不是点，就用数字把点替换掉
      if (str.length > 1 && len1 == 0 && len2 != '.') {
        str = str.substr(1, 1)
      }
      if (len1 == '.') {
        str = ''
      }
      //限制只能输入一个小数点
      if (str.indexOf('.') != -1) {
        var str_ = str.substr(str.indexOf('.') + 1)
        if (str_.indexOf('.') != -1) {
          str = str.substr(0, str.indexOf('.') + str_.indexOf('.') + 1)
        }
        if (str_.length > 2) {
          this.$message.warning(`金额小数点后只能输入两位，请正确输入！`)
          return (str = '')
        }
      }
      //正则替换
      str = str.replace(/[^\d.]/g, '') // 保留数字和小数点
      return str
    },
    async getTrainerInfo() {
      const [res, err] = await to(findByIdTrainer({ id: this.id }))
      if (err) return this.$message.warning(err.msg)
      this.form = res.data
      if (res.data.teacherType == 2) {
        this.Isable = false
      }
      this.city = [res.data.provinceCode, res.data.cityCode]
      this.form.Address = this.city
    },
    handleChooseradio() {
      if (this.form.teacherType == 1) {
        this.Isable = true
      } else if (this.form.teacherType == 2) {
        this.Isable = false
      }
    },
    selectTrainer() {
      this.$refs.TrainerDialog.show(this.teachers)
    },
    handleTeachersChange(val) {
      this.teachers = val
      this.form.userName = this.teachers.studentName
      this.form.phone = this.teachers.mobile
      this.form.userId = this.teachers.userId
    },
    handleChangeAddress(val) {
      this.form.provinceCode = val[0]
      this.form.provinceName = this.$refs.myCascader.getCheckedNodes()[0].pathLabels[0]
      this.form.cityName = this.$refs.myCascader.getCheckedNodes()[0].pathLabels[1]
      this.form.cityCode = val[1]
      this.form.Address = val
    },
    submit() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.id ? this.update() : this.create()
        }
      })
    },
    async create() {
      this.loading = true
      const [, err] = await to(saveTrainer(this.form))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('添加成功')
      this.$router.push('/teacher/trainer')
    },
    async update() {
      this.loading = true
      const [, err] = await to(updataTrainer({ ...this.form, id: this.id }))
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.$message.success('修改成功')
      this.$router.push('/teacher/trainer')
    },
  },
}
</script>
<style lang="scss" scoped>
.course-library-add {
  .el-radio-button {
    margin-bottom: 20px;
  }
  .el-form-item .el-form-item {
    margin-bottom: 20px;
  }
  .el-button.el-button--default {
    margin-left: 15px;
  }
  ::v-deep.uploader {
    height: 92px;
    .el-upload {
      width: 180px;
      height: 100%;
      background: #f5f5f5;
      border-radius: 5px;
    }
    .el-upload-list__item {
      width: 180px;
      height: 100%;
    }
  }
  ::v-deep.el-upload-dragger {
    background: #f5f5f5;
  }
}
</style>
